<template>
  <v-container class="safeMargin home-container pa-0">




    <template
      v-if="$store.state.Auth.token.claims.type != 'admin' && $store.state.Auth.token.claims.type != 'superuser'">

      <!-- Hero Section -->
      <div class="hero-section">
        <v-card class="hero-card">
          <div class="hero-content">
            <div class="user-welcome">
              <span class="greeting">
                <!-- greeting hand emoji -->


                Hola,</span>
              <span class="user-name">{{ $store.state.Auth.token.claims.name.split(" ")[0] }}</span>
              <span class="ml-2 hand-emoji">👋</span>
            </div>
            <div class="motivation-text">
              <!-- rocket emoji -->


              ¡Es hora de entrenar!
              <span class="rocket-emoji">🚀</span>
            </div>
          </div>
        </v-card>
      </div>


      <!-- Progress Section -->
      <v-card class="progress-card mx-4 mt-n8 rounded-lg">
        <WeeklyProgress :daysUsed="workoutsCompleted" :maxDays="user ? user.plan : 7"
          :workedOutToday="workedOutToday" />
      </v-card>

      <PendingRatings />


      <!-- Quick Actions -->
      <div class="quick-actions px-4 mt-6">
        <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
          <v-col v-for="(item, index) in menuItems" :key="index" cols="4" class="pa-2">
            <v-card :ripple="true" class="action-card overflow-hidden rounded-lg" @click="$router.push(item.route)">
              <div class="action-content">
                <v-icon :color="item.color" size="32">{{ item.icon }}</v-icon>
                <span class="action-title">{{ item.title }}</span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>



      <!-- WhatsApp Communication Button -->
      <div class="whatsapp-section px-5 mt-6 mb-6">
        <v-btn block color="success" elevation="4" height="64" class="whatsapp-button"
          href="https://wa.me/+59891272285?text=Hola, ..." target="_blank" rel="noopener noreferrer">
          <div class="whatsapp-button-content">
            <div class="whatsapp-icon-wrapper">
              <v-icon size="32">mdi-whatsapp</v-icon>
            </div>
            <div class="whatsapp-text-content">
              <span class="button-title">¿Necesitas ayuda?</span>
              <span class="button-subtitle">Contacto via WhatsApp</span>
            </div>
          </div>
        </v-btn>
      </div>

    </template>

    <template v-else>

      <!-- Hero Section -->
      <div class="hero-section2">
        <v-card class="hero-card">
          <div class="hero-content pt-0">
            <div class="user-welcome">
              <span class="greeting">
                <!-- greeting hand emoji -->


                Hola,</span>
              <span class="user-name">{{ $store.state.Auth.token.claims.name.split(" ")[0] }}</span>
              <span class="ml-2 hand-emoji">👋</span>
            </div>
            <div class="motivation-text">
              <!-- rocket emoji -->


              Estado del centro
              <span class="rocket-emoji">🏠</span>
            </div>
          </div>
        </v-card>
      </div>
      <AdminDashboard style="margin-top: -60px;"></AdminDashboard>
    </template>

  </v-container>
</template>

<script>
import moment from 'moment';
import UserImage from '@/components/profile/UserImage.vue';
import WeeklyProgress from '@/components/training/WeeklyProgress.vue';
import AdminDashboard from '@/components/dashboard/AdminDashboard.vue';

import {
  getFirestore, collection, query, where, getDocs, doc, getDoc, limit, orderBy, addDoc, Timestamp
} from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';

import PendingRatings from '@/components/workouts/PendingRatings.vue';

export default {
  components: {
    UserImage,
    WeeklyProgress,
    AdminDashboard,
    PendingRatings
  },
  data() {
    return {
      workoutsCompleted: -1,
      user: null,
      alert: false,
      workedOutToday: false,
      userImg: null,
      menuItems: [
        {
          title: "Clases",
          icon: 'mdi-calendar-clock',
          route: '/clases',
          color: 'primary'
        },
        {
          title: 'Progreso',
          icon: 'mdi-finance',
          route: '/progreso',
          color: 'success'
        },
        {
          title: 'Mi plan',
          icon: 'mdi-play-circle',
          route: '/entrenamiento',
          color: 'primary'
        },

        {
          title: 'Actividad',
          icon: 'mdi-chart-timeline-variant',
          route: '/actividad',
          color: 'info'
        },
        /*   {
            title: 'Comunidad',
            icon: 'mdi-bullhorn',
            route: '/comunidad',
            color: 'warning'
          }, */
        {
          title: 'Cronómetro',
          icon: 'mdi-timer',
          route: '/cronometro',
          color: 'warning'
        },
        {
          title: 'Reportar problema',
          icon: 'mdi-alert-circle',
          route: '/reportar-problema',
          color: 'error'
        }
      ]
    }
  },

  created() {

    this.userImg = { id: this.$store.state.Auth.token.claims.user_id }
  },

  async mounted() {
    this.$vuetify.goTo(0);

    logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Loaded home page`);
    await this.getUser();
    this.workoutsCompleted = await this.getWorkoutsCompleted(this.$store.state.Auth.token.claims.user_id)

  },
  methods: {
    async setAlert(type, date, description) {
      const db = getFirestore();

      let userID = this.$store.state.Auth.token.claims.user_id;

      try {
        const timestampDate = Timestamp.fromDate(date);

        const newAlert = {
          user_id: userID,
          type: type,
          date: timestampDate,
          description: description,
          seen: false
        };

        await addDoc(collection(db, 'alerts'), newAlert);

        // now add to the alert user subcollection
        //const alertRef = collection(db, `users/${userID}/alerts`);
        //await addDoc(alertRef, newAlert);


      } catch (error) {
        console.error("Error adding alert:", error);
        this.checkConnectivityError(error);
        await logAuditEvent('error', this.$store.state.Auth.token.claims.user_id,
          `Error adding alert for user home page ${userID}: ${error.message}`
        );
      }
    },
    showData(route) {
      this.$router.push(route);
    },
    async getWorkoutsCompleted(user) {
      try {
        const db = getFirestore()
        const workoutsRef = collection(db, `workouts`);
        const startOfWeek = this.getStartOfWeek();
        const endOfWeek = this.getEndOfWeek();

        const q = query(workoutsRef,
          where("date", ">=", startOfWeek),
          where("date", "<", endOfWeek),
          where("user_id", "==", user)
        );
        const workoutDocs = await getDocs(q);

        let workoutsCompleted = workoutDocs.size
        const workoutDates = workoutDocs.docs.map(doc => doc.data().date.toDate().toISOString())

        const isSameDay = workoutDates.some(date => moment(date).isSame(moment(), 'day'))

        if (isSameDay) {
          this.workedOutToday = true
        }

        return workoutsCompleted

      } catch (error) {
        console.error("Error getting workouts:", error);
        this.checkConnectivityError(error);
        return 0;
      }
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff, 0, 0, 0, 0);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59, 999);
      return endOfWeek;
    },
    async getUser() {
      try {
        const db = getFirestore()
        const userId = this.$store.state.Auth.token.claims.user_id;
        const type = this.$store.state.Auth.token.claims.type;
        // Get user and payment data in parallel
        const [userData, paymentDocs] = await Promise.all([
          getDoc(doc(db, `users/${userId}`)),
          getDocs(query(
            collection(db, `payments`),
            where("id", "==", userId),
            where("endOfSubscription", ">", new Date()),
            orderBy("endOfSubscription", "asc"),
            limit(1)
          ))
        ]);

        // Merge user data
        let data = userData.exists() ? userData.data() : false;

        if (!data) {
          return;
        }

        // Handle plan logic
        if (!paymentDocs.empty) {
          const lastPayment = paymentDocs.docs[0].data();
          data.plan = lastPayment.amount;
        } else {
          data.plan = 0;
        }

        // Only check schedule if plan is 0
        if (data.plan === 0) {

          if (data.type && data.type.includes('online')) {
            data.plan = 7;
          } else {
            const scheduleIntervalsRef = doc(db, `configurations/schedule`);
            const scheduleIntervals = await getDoc(scheduleIntervalsRef);
            const scheduleIntervalsData = scheduleIntervals.data();
            let maxDays = 0;

            ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'].forEach(day => {
              if (scheduleIntervalsData[day].length > 0) maxDays += 1;
            });

            data.plan = maxDays;
          }
        }


        this.user = data;

        if (data.disabled) {
          let today = moment().toDate()
          await this.setAlert('user_disabled', today, null)
          this.$notify({
            group: "feedback",
            title: "Error",
            type: "error",
            text: "Tu usuario ha sido deshabilitado, contacta con el administrador.",
          });

          this.logout();

        }

        if (this.user.endOfSubscription) {
          let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
          let today = moment().toDate()
          if (endOfSubscription < today) {
            this.setAlert('user_subscription_expired', today, null)

            this.$router.push({ name: 'suscripcion' })

          } else {
            //calculate diffs and if less than 5 days, show alert set this.alert=true

            let diff = moment(endOfSubscription).diff(today, 'days');
            if (diff > 0 && diff <= 5) {

              this.alert = diff;
            }

            if (diff <= 0) {
              //await this.setAlert('user_subscription_expired', today, null)
              this.$router.push({ name: 'suscripcion' })
            }

          }
        }

        let today = moment().toDate()

        //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
        if (this.user.licensePaused) {
          //await this.setAlert('user_license_paused', today, null)
          this.$notify({
            group: "feedback",
            title: "Error",
            type: "error",
            text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
          });
          this.$router.push('/perfil')
        }

        //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
        // Query Firestore for licenses issued before today
        const licensesRef = collection(db, `users/${userId}/licenses`);
        const q = query(licensesRef, where("issuedOn", "<", today));

        const licenseDocs = await getDocs(q);
        const filteredLicenses = licenseDocs.docs
          .map(doc => doc.data())
          .filter(license => {
            if (license.resumedOn) {
              return new Date(license.resumedOn.seconds * 1000) > today;
            } else if (typeof license.resumedOn === 'undefined') {
              return true;
            }

          }); // Filter by resumedOn in client

        if (filteredLicenses.length === 0) {
          //console.log('No matching documents.');
        } else {
          this.$notify({
            group: "feedback",
            title: "Error",
            type: "error",
            text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
          });
          this.$router.push('/perfil')
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (!this.checkConnectivityError(error)) {
          this.$notify({
            group: "feedback",
            title: "Error",
            type: "error",
            text: "Error al cargar datos del usuario. Por favor intenta nuevamente."
          });
        }
        return;
      }
    },
    checkConnectivityError(error) {
      console.log(error)
      // Check common Firebase offline/connectivity error codes
      if (error.code === "unavailable" ||
        error.code === "auth/network-request-failed" ||
        error.message?.includes('network') ||
        !navigator.onLine) {

        this.$notify({
          group: "feedback",
          duration: 5000,
          type: "error",
          title: "Sin conexión",
          text: "No hay conexión a internet. Por favor verifica tu conexión e intenta nuevamente."
        });
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.hero-section {
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  height: 220px;
  border-radius: 0 0 30px 30px;
  position: relative;
  padding: 24px;
}

.hero-section2 {
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  height: 180px;
  border-radius: 0 0 30px 30px;
  position: relative;
  padding: 24px;
}

.hero-card {
  background: transparent !important;
  box-shadow: none !important;
}

.hero-content {
  color: white;
  padding-top: 20px;
}

.user-welcome {
  font-size: 2rem;
  margin-bottom: 8px;
}

.greeting {
  font-weight: 300;
}

.user-name {
  font-weight: 600;
  margin-left: 8px;
}

.motivation-text {
  font-size: 1.2rem;
  opacity: 0.9;
  font-weight: 300;
}

.progress-card {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--v-primary-darken1);
}

.action-card {
  transition: all 0.3s ease;
  height: 100px;
  cursor: pointer;
}

.action-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1) !important;
}

.action-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.action-title {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 600px) {
  .hero-section {
    height: 180px;
  }

  .hero-content {
    padding-top: 5px;
  }

  .user-welcome {
    font-size: 1.75rem;
  }
}

.whatsapp-button {
  border-radius: 10px;
  background: linear-gradient(45deg, #25d3668f, #128C7E) !important;
  position: relative;
  overflow: hidden;
}

.whatsapp-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.whatsapp-button:hover::before {
  opacity: 1;
}

.whatsapp-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(37, 211, 102, 0.3) !important;
}

.whatsapp-button-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
}

.whatsapp-icon-wrapper {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.button-title {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.5px;
}

.button-subtitle {
  font-size: 0.9rem;
  opacity: 0.9;
  font-weight: 400;
}
</style>
